import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';

export enum ThemeEnum {
  LIGHT = 'theme-light',
  DARK = 'theme-dark',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public static defaultTheme = ThemeEnum.DARK;
  public static currentTheme: ThemeEnum;

  constructor(private platform: Platform) {}

  async switchTheme(): Promise<void> {
    let theme = ThemeService.currentTheme;
    const body = document.querySelector('body');
    const ionApp = document.querySelector('ion-app');
    if (theme === ThemeEnum.DARK) {
      body?.classList.replace(ThemeEnum.DARK, ThemeEnum.LIGHT);
      ionApp?.classList.replace(ThemeEnum.DARK, ThemeEnum.LIGHT);
      this.setActiveTheme(ThemeEnum.LIGHT);
      if (this.platform.is('capacitor')) await StatusBar.setStyle({ style: Style.Dark });
    } else {
      body?.classList.replace(ThemeEnum.LIGHT, ThemeEnum.DARK);
      ionApp?.classList.replace(ThemeEnum.LIGHT, ThemeEnum.DARK);
      this.setActiveTheme(ThemeEnum.DARK);
      if (this.platform.is('capacitor')) await StatusBar.setStyle({ style: Style.Light });
    }
  }

  setActiveTheme(theme: ThemeEnum) {
    ThemeService.currentTheme = theme;
    localStorage.setItem('theme', theme.toString());
  }

  async setupTheme(): Promise<void> {
    const body = document.querySelector('body');
    const ionApp = document.querySelector('ion-app');
    let theme = localStorage.getItem('theme') as ThemeEnum;
    if (!theme) {
      theme = ThemeService.defaultTheme;
      body?.classList.add(ThemeEnum.DARK);
      ionApp?.classList.add(ThemeEnum.DARK);
      ThemeService.currentTheme = theme;
      this.setActiveTheme(theme);
    } else {
      if (theme === ThemeEnum.DARK) {
        body?.classList.add(ThemeEnum.DARK);
        ionApp?.classList.add(ThemeEnum.DARK);
      } else {
        body?.classList.add(ThemeEnum.LIGHT);
        ionApp?.classList.add(ThemeEnum.LIGHT);
      }
      ThemeService.currentTheme = theme;
    }
  }
}
