import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ParamService {
  constructor(private httpClient: HttpClient) {}

  getParamValue(theme: string, key: string): Observable<string> {
    return this.httpClient
      .get<Param>(environment.apiUrl + '/params/' + theme + '/' + key)
      .pipe(switchMap(async (param) => param.value));
  }

  getParam(theme: string, key: string): Observable<Param> {
    return this.httpClient.get<Param>(environment.apiUrl + '/params/' + theme + '/' + key);
  }

  setParamCgu(cguParam: CguParam): Observable<CguParam> {
    return this.httpClient.post<CguParam>(environment.apiUrl + '/params/cgu', cguParam);
  }
}

type CguParamValue = {
  text: string;
  link: string;
};
type CguParamLang = {
  en: CguParamValue;
  fr: CguParamValue;
  es: CguParamValue;
  de: CguParamValue;
};
type CguParam = {
  cgu: CguParamLang;
  terms: CguParamLang;
  consent: CguParamLang;
};

export type Param = {
  theme: string;
  key: string;
  value: string;
  link: string;
  byDefault: boolean;
};
