import { Injectable } from '@angular/core';
import { NotificationInterface } from './notification.interface';
import { Notification } from '../../models/notification.model';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements NotificationInterface {
  constructor(private httpClient: HttpClient) {}

  getUserNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(environment.apiUrl + '/notifications');
  }

  setNotificationsRead(): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl + '/notifications/read', null);
  }

  setNotificationRead(notificationId: number): Observable<number> {
    return this.httpClient.get<{ length: number }>(environment.apiUrl + '/notifications/read/' + notificationId).pipe(
      switchMap(async (notif) => {
        return notif.length;
      }),
    );
  }

  howManyNotificationNotRead(): Observable<number> {
    return this.httpClient.get<{ length: number }>(environment.apiUrl + '/notifications/notread/length').pipe(
      switchMap(async (notif) => {
        return notif.length;
      }),
    );
  }

  getProfilePictureFromNotification(notification: Notification): Observable<Notification> {
    return this.httpClient.get<Notification>(environment.apiUrl + '/picture/user/min/' + notification.userIdFrom);
  }

  deleteNotification(notification: Notification): Observable<boolean> {
    return this.httpClient.delete<boolean>(environment.apiUrl + '/notifications/' + notification.id);
  }

  deleteAllNotifications(): Observable<boolean> {
    return this.httpClient.delete<boolean>(environment.apiUrl + '/notifications/deleteAll');
  }
}
